import { useCallback } from 'react';
import { invalidatePersistedAuthorizedUserData } from '../Provider/AuthenticationProvider/PersistedAuthorizedUserData';
import { window } from '../../../../js/globals';
import { useWithSiteUrl } from '../../../general/WebshopContext/WebshopContext';
import Url from '../../../../js/util/Url';

const useLogout = (): () => void => {
    const withSiteUrl = useWithSiteUrl();

    return useCallback(() => {
        invalidatePersistedAuthorizedUserData();

        if (window) {
            const redirectPath = Url.getPathWithoutSubLanguage(window.location.href);

            const logoutUrl = new URL(withSiteUrl('/auth/logout'));
            logoutUrl.searchParams.set('redirect', redirectPath ?? '');

            window.location.href = logoutUrl.toString();
        }
    }, [withSiteUrl]);
};

export default useLogout;
