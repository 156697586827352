import { sessionStorage } from '../../../../../js/util/storage';
import type { UserData } from '../../types';

const sessionStorageKey = 'authorized-user-data';

const getPersistedAuthorizedUserData = (): UserData | null => {
    if (!sessionStorage) {
        return null;
    }

    try {
        return JSON.parse(sessionStorage.getItem(sessionStorageKey) ?? '') as UserData;
    } catch (error) {
        return null;
    }
};

const persistAuthorizedUserData = (userData: UserData): void => {
    if (!sessionStorage) {
        return;
    }

    try {
        sessionStorage.setItem(sessionStorageKey, JSON.stringify(userData));
    } catch (error) {
        // Invalid user data supplied or the session storage is not writable.
    }
};

/**
 * When the login status switches, we want to clear the persisted data and allow the fresh data to be retrieved and persisted from the server.
 * This prevents a mismatch in persisted and server data when loading a page for the first time after a login status change.
 */
const invalidatePersistedAuthorizedUserData = (): void => {
    if (!sessionStorage) {
        return;
    }

    try {
        sessionStorage.setItem(sessionStorageKey, '');
    } catch (error) {
        // Session storage is not writable.
    }
};

export {
    getPersistedAuthorizedUserData,
    persistAuthorizedUserData,
    invalidatePersistedAuthorizedUserData,
};
