import type { QueryFunction, QueryKey } from '@tanstack/react-query';
import type { Potential } from '../../../../js/types';
import { isObject } from '../../../../js/typeguards';
import fetch from '../../../../js/api/fetch';
import type { UserData as UserResponse } from '../types';

const isUserResponse = (data: Potential<UserResponse>): data is UserResponse => (
    isObject(data)
    && typeof data.loggedIn === 'boolean'
);

const fetchUser = async (signal?: AbortSignal): Promise<UserResponse> => {
    const response = await fetch('/auth/user', { signal });

    const responseData = await response.json() as Potential<UserResponse>;

    if (isUserResponse(responseData)) {
        return responseData;
    }

    throw new Error('Unexpected data returned');
};

type AuthorizedUserQueryOptions = {
    gcTime: number;
    queryFn: QueryFunction<UserResponse>;
    queryKey: QueryKey;
};

const authorizedUserQuery = (): AuthorizedUserQueryOptions => ({
    gcTime: Infinity,
    queryFn: ({ signal }) => fetchUser(signal),
    queryKey: ['authorized-user-data'],
});

export default authorizedUserQuery;
