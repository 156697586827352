import type { MouseEventHandler } from 'react';
import type React from 'react';
import { useEffect } from 'react';
import classNames from 'classnames';
import { document } from '../../../js/globals';
import styles from './Backdrop.module.scss';

interface OverlayProps {
    easing?: boolean;
    isOpen?: boolean;
    onClose?: () => void;
}

const Backdrop: React.FC<React.PropsWithChildren<OverlayProps>> = ({ children, easing = false, isOpen = true, onClose }) => {
    useEffect(() => {
        if (isOpen && document) {
            document.body.style.overflow = 'hidden';
            document.documentElement.classList.add('fancybox-margin', 'fancybox-lock');
        }

        return () => {
            if (document) {
                document.body.style.overflow = 'unset';
                document.documentElement.classList.remove('fancybox-margin', 'fancybox-lock');
            }
        };
    }, [isOpen]);

    useEffect(() => {
        if (!onClose) {
            return () => null;
        }

        const listener = (e:KeyboardEvent) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        if (isOpen && document) {
            document.body.addEventListener('keydown', listener);
        }

        return () => {
            document?.body.removeEventListener('keydown', listener);
        };
    }, [isOpen, onClose]);

    const modalClickHandler: MouseEventHandler = (e) => {
        e.stopPropagation();
    };

    return (
        <div
            onClick={onClose}
            role="presentation"
            className={classNames(styles.Backdrop, {
                [styles.Backdrop_open as string]: isOpen,
                [styles.Backdrop_easing as string]: easing,
            })}
        >
            <div onClick={modalClickHandler}>
                {children}
            </div>
        </div>
    );
};

export default Backdrop;
